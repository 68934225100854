import React, { useRef, useState } from "react"
import "./ecosystemEnd.scss"

export const EcosystemEnd: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isButtonVisible, setIsButtonVisible] = useState(true)
  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setIsButtonVisible(false)
    }
  }
  return (
    <>
      <section>
        <div className="software-partners">
          <h3 className=" my-8">Software Benefits</h3>
          <p>
            Participating startups gain access to a selection of software and
            tools credits from leading providers including:
          </p>
          <div className="software-benefits grid grid-cols-1 sm:grid-cols-1 gap-4 p-4">
            <img
              src="/images/software_partners/aws.svg"
              alt="AWS"
              className="mx-auto"
            />
            <img
              src="/images/software_partners/hubspot.svg"
              alt="HubSpot"
              className="mx-auto"
            />
            <img
              src="/images/software_partners/notion.svg"
              alt="Notion"
              className="mx-auto"
            />
            <img
              src="/images/software_partners/google_cloud.svg"
              alt="Google Cloud"
              className="mx-auto"
            />
          </div>
        </div>
      </section>
      <section>
        <div className="demo-video max-w-sm rounded overflow-hidden shadow-lg">
          <div className="demo-video-inner">
            <h2 className="text-center demo-day-title">Demo Day Video</h2>
            <div className="video-container relative">
              {isButtonVisible && (
                <button
                  onClick={playVideo}
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10"
                >
                  <img src="/images/play-button.svg" alt="Play" />
                </button>
              )}
              <video
                ref={videoRef}
                src="/videos/demo-day.mp4"
                title="Demo Day Video"
                controls
                poster="/images/demo-day-cover.png"
              >
                <track
                  src="/path/to/captions.vtt"
                  kind="captions"
                  srcLang="en"
                  label="English"
                  default
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
