import React, { useState } from "react"
import { Link } from "gatsby"
import { Page } from "../components/layout/Page"
import partnerData from "../data/partnerData.json"
import { EcosystemEnd } from "../components/layout/EcosystemEnd"
import "./partner.scss"

interface SinglePartnerProps {
  partner: {
    name: string
    logo: string
  }
}

export const SinglePartner: React.FC<SinglePartnerProps> = ({
  partner: { name, logo },
}) => (
  <div className="ecosystem-partner">
    <img src={logo} alt={name} className="partner-logo" />
    <div className="partner-name">{name}</div>
  </div>
)

export const Partners: React.FC = () => (
  <section className="partners-section">
    <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 partners-grid">
      {partnerData.map((partner) => (
        <SinglePartner partner={partner} key={partner.name} />
      ))}
    </div>
  </section>
)

export const Partner = () => {
  const [selectedTab, setSelectedTab] = useState(`Partners`)

  return (
    <Page
      header=""
      title="Partners"
      keywords="ecosystem partners, xrpl accelerator ecosystem, xrpl accelerator partners, web3 experts, blockchain experts."
      description="Meet the XRPL Accelerator Partners"
      slug="partners"
    >
      <h1 className="text-center ecosystem-header">
        Accelerator <br /> Ecosystem
      </h1>
      <div className="eco-tabs">
        <Link
          to="/ecosystem"
          className={`eco-toggle mentors-toggle selected ${
            selectedTab === `Mentors` ? `selected-mentors` : ``
          }`}
          onClick={() => setSelectedTab(`Mentors`)}
        >
          Mentors
        </Link>
        <Link
          to="/partner"
          className={`eco-toggle partners-toggle selected ${
            selectedTab === `Partners` ? `selected-partners` : ``
          }`}
          onClick={() => setSelectedTab(`Partners`)}
        >
          Partners
        </Link>
        <Link
          to="/alumni"
          className={`eco-toggle alumnis-toggle selected ${
            selectedTab === `Alumnis` ? `selected-alumnis` : ``
          }`}
          onClick={() => setSelectedTab(`Alumnis`)}
        >
          Project Alumni
        </Link>
      </div>
      {selectedTab === `Partners` && <Partners />}
      <EcosystemEnd />
    </Page>
  )
}
export default Partner
